import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"
import BuyButton from "../components/buy"
import Footer from "../components/footer"
import Bunny from "../components/images/bunny"
import Bingo from "../components/images/bingo"
import Vini from "../components/images/vini"

const Language = "de-DE"

const IndexPage = () => (
  <Layout>
    <SEO title="Osterpraline | Vegane Schokoladen" lang={Language} />
    <Logo className="mx-auto h-auto w-56 pt-8 lg:absolute lg:z-10 lg:left-0 lg:ml-12" route="https://candide.berlin/startseite" />
    <div className="px-6 text-center md:flex md:items-center">
      <div className="md:w-1/2">
        <Bunny className=""/>
      </div>
      <div className="md:w-1/2 md:text-left md:pr-8">
        <h2 className="mt-8 text-4xl font-bold leading-tight text-gray-800 md:text-5xl md:leading-none lg:text-6xl">Das gibt's nur zu Ostern.</h2>
        <p className="mt-2 text-xl font-medium leading-tight text-canblue md:mt-3 lg:mt-4 lg:text-2xl lg:leading-none lg:tracking-wide">Wasserganache aus Beni Wild und Karotte.</p>
        <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">
          Scotty ist ein blauer Hase, der Karotten liebt. 
          Zu Ostern basteln wir mit ihm eine Praline: 
          <span className="font-bold text-gray-800"> Vegan mit Karotte.</span>
        </p>
        <p className="mt-4 text-lg text-gray-800 md:font-thin">Die Auflage ist limitiert. Wer sie Ostern geniessen möchte, muss jetzt reservieren.</p>
        <div className="mt-4 md:flex md:items-end md:justify-start">
          <BuyButton className="pt-2 md:mx-0" shopifyId="4420557471878" title="ABHOLEN" background="#327BB3" color="#ffffff" border="1px solid #327BB3" showVariantOptions={true} />
          <BuyButton className="mt-4 border-t md:mx-0 md:border-0 md:ml-4" shopifyId="4417770258566" title="VERSAND" background="#718096" color="#ffffff" border="1px solid #718096" />
        </div>
        <p className="mt-4 text-sm text-gray-600 italic md:font-thin">Reservieren bis 31. März 2020. <br /> Selber abholen oder wir senden zu euch nach Hause.</p>
      </div>
    </div>
    <div className="mt-12 bg-canblue h-40 flex items-center justify-center text-center md:mt-0 lg:justify-end lg:text-right">
      <p className="text-white tracking-wide italic px-8 md:px-20 md:font-thin">"Karotte und Schokolade? dachte ich zuerst. Aber ja, das passt super. Diese Teile sind genial. Unbeding probieren!!" <br />Ein gekauftes Zitate von einem Freund</p>
    </div>
    <div className="px-6 border-t-8 border-canred">
      <div className="text-center max-w-xl mx-auto">
        <h3 className="mt-12 text-3xl font-bold text-gray-800 md:text-4xl lg:mt-20">Abholtermine</h3>
        <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Entweder wir senden euch die Pralinen nach Hause. <br />Oder ihr kommt zu einem Abholtermin.</p>
        <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Bitte wählt eine der folgenden Optionen:</p>
      </div>
    </div>
    <div className="mt-8 max-w-5xl mx-auto text-center md:flex md:items-center md:shadow-xl md:border md:border-gray-200 lg:rounded-lg md:overflow-hidden">
      <div className="md:w-1/3">
        <Bingo className="md:h-64" />
      </div>
      <div className="md:w-1/3 md:text-left md:pl-10 md:border-r md:border-gray-200">
        <h4 className="mt-8 text-2xl font-bold leading-tight text-gray-800 md:mt-0">Bunny Bingo</h4>
        <h5 className="text-lg text-gray-800 md:font-thin">Zum Starken August</h5>
        <ul className="mt-4 text-lg text-gray-800 md:font-thin">
          <li>Schönhauser Allee 56</li>
          <li>10437 Berlin (City Ost)</li>
        </ul>
        <p className="mt-4 text-lg text-gray-800 md:font-thin">20:00 bis 23:00</p>
        
      </div>
      <div className="md:w-1/3">
        <div className="font-bold text-gray-800 uppercase tracking-wide">
          <span className="leading-none">Montag</span>
          <span className="pl-2 md:block md:pl-0 md:text-4xl md:leading-none ">6. April</span>
        </div>
        <BuyButton shopifyId="4420557471878" variantId="31445164621958" title="ABHOLEN" />

      </div>
    </div>
    <div className="mt-12 max-w-5xl mx-auto text-center md:flex md:items-center md:shadow-xl md:border md:border-gray-200 lg:rounded-lg md:overflow-hidden">
      <div className="md:w-1/3">
        <Vini className="md:h-64" />
      </div>
      <div className="md:w-1/3 md:text-left md:pl-10 md:border-r md:border-gray-200">
        <h4 className="mt-8 text-2xl font-bold leading-tight text-gray-800 md:mt-0">Vini Culture</h4>
        <ul className="mt-4 text-lg text-gray-800 md:font-thin">
          <li>Grolmannstrasse 44-45</li>
          <li>10623 Berlin (City West)</li>
        </ul>
        <p className="mt-4 text-lg text-gray-800 md:font-thin">17:00 bis 20:00</p>
        
      </div>
      <div className="md:w-1/3">
        <div className="font-bold text-gray-800 uppercase tracking-wide">
          <span className="leading-none">Dienstag</span>
          <span className="pl-2 md:block md:pl-0 md:text-4xl md:leading-none ">7. April</span>
        </div>
        <BuyButton shopifyId="4420557471878" variantId="31445164654726" title="ABHOLEN" />
      </div>
    </div>
    <div className="mt-12 px-6 py-12 bg-gray-100 text-center">
      <h3 className="text-3xl font-bold text-gray-800 md:text-4xl">Versand</h3>
      <p className="mt-4 text-lg text-gray-800 md:font-thin">Nein, ich kann nicht zum Abholen kommen.<br />Ich möchte die Schokoladen daheim erhalten.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin">Die Sendungen gehen am Montag raus. <br/>Mit etwas Glück sind sie am Dienstag da.</p>        
      <BuyButton className="mt-2" shopifyId="4417770258566" variantId="all" title="SENDEN" background="#718096" color="#ffffff" border="1px solid #718096" />
    </div>
    <div className="mt-12 px-6 text-center">
      <h3 className="mt-12 text-3xl font-bold text-gray-800 leading-tight md:text-4xl lg:mt-20">Klartext</h3>
      <p className="text-lg font-medium text-canblue md:font-thin lg:font-hairline">Nochmal die Fakten.</p>
      <p className="mt-6 text-lg text-gray-800 md:font-thin lg:font-hairline">Wir machen eine Osterpraline.<br />Mit <a href="https://www.instagram.com/scottybunny" className="text-canblue underline">Scotty the Blue Bunny.</a></p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Die Schokoladen gibt es nur an Ostern. <br />Und nur auf Vorbestellung. </p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Sie sind aus Beni Wild und Karotte. <br />Wasserganache - also VEGAN. </p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Es ist eine limitierte Auflage mit Osterkarte. <span className="block font-bold">€29,95 für 18 Stück</span></p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Du bestellst sie online. <br/>Und entweder wir senden sie dir. <br />Oder du holst sie bei einem der Termine ab. </p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Sag uns bitte bescheid, wie’s dir passt.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Es gibt ein Wein Pairing zu den Schokoladen.<br />Das kannst du mitbestellen oder bei ViniCulture kaufen.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Alles klar?</p>
    </div>
    <Footer lang={Language} />
  </Layout>
)

export default IndexPage
